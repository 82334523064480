import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import ZoomInfoHeaderWrapper from "./zoominfoHeader.style";
import { Link } from "gatsby";
const ZoomInfoHeader = () => {
  const ZoominfoPageData = useStaticQuery(graphql`
    {
      allStrapiAffiliatePageHeaderImage {
        nodes {
          image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `);

  const img = ZoominfoPageData.allStrapiAffiliatePageHeaderImage.nodes[0];

  return (
    <ZoomInfoHeaderWrapper>
      <div className="text">
        <h1>Boost Your Income with Our Affiliate Program</h1>
        <p>
          Promote our platform to your audience and earn recurring commissions
          of up to 30% on each paying customer you refer.
        </p>
        <Link target={"_blank"} href="https://clodura.firstpromoter.com/">
          <button>Join now and start earning!</button>
        </Link>
      </div>

      <img className="right-img" src={`${img.image.localFile.publicURL}`} />
    </ZoomInfoHeaderWrapper>
  );
};

export default ZoomInfoHeader;
