import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import ZoomInfoHeaderWrapper from "./zoominfoHeader.style";
import { Link } from "gatsby";

const ZoomInfoHeader = () => {
  const ZoominfoPageData = useStaticQuery(graphql`
    {
      allStrapiAffiliatePageImage {
        nodes {
          img1 {
            localFile {
              publicURL
            }
          }
          img2 {
            localFile {
              publicURL
            }
          }
          img3 {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `);

  const img = ZoominfoPageData.allStrapiAffiliatePageImage.nodes[0];

  return (
    <>
      <ZoomInfoHeaderWrapper>
        <div className="text">
          <h1>How it works? </h1>
          <p>
            1. Sign up for our Affiliate Program and receive your unique
            referral link.
          </p>
          <p>
            2. Promote Clodura.AI to your audience using your referral link.
          </p>
          <p>
            3. When someone clicks on your link and subscribes to our platform,
            you earn a commission on the sale.
          </p>
          <p>
            4. Track your earnings and performance in real-time through our
            easy-to-use affiliate dashboard.
          </p>
        </div>

        <img className="right-img" src={`${img.img1.localFile.publicURL}`} />
      </ZoomInfoHeaderWrapper>
      <ZoomInfoHeaderWrapper>
        <img className="right-img" src={`${img.img2.localFile.publicURL}`} />
        <div className="text">
          <h1>Why join our Affiliate Program?</h1>
          <p>1. Earn up to 30% commission on every sale for first year</p>
          <p>
            2. Access to high-converting marketing materials to help you promote
            our products.
          </p>
          <p>
            3. Real-time tracking and reporting to help you optimize your
            performance.
          </p>
          <p>
            4. Dedicated affiliate support team to assist you every step of the
            way.
          </p>
        </div>
      </ZoomInfoHeaderWrapper>
      <ZoomInfoHeaderWrapper>
        <div className="text">
          <h1>Who can join? </h1>
          <p>
            Anyone with a website, blog, or social media following can join our
            Affiliate Program. Whether you're an influencer, blogger, or content
            creator, our program is open to anyone with a passion for our
            products and a desire to earn money promoting them.
          </p>
          <p>
            Join our Affiliate Program today and start earning commissions on
            every sale you refer!
          </p>
        </div>

        <img className="right-img" src={`${img.img3.localFile.publicURL}`} />
      </ZoomInfoHeaderWrapper>
      <ZoomInfoHeaderWrapper>
        <Link target={"_blank"} href="https://clodura.firstpromoter.com/">
          <button>Join Our Affiliate Program</button>
        </Link>
      </ZoomInfoHeaderWrapper>
    </>
  );
};

export default ZoomInfoHeader;
