import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import { saasModernTheme } from 'common/theme/saasModern';
import { ResetCSS } from 'common/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'containers/SaasModern/sassModern.style';
import Navbar from 'containers/SaasModern/Navbar';
import Banner from 'containers/Custom/Features/FeatureBannerForAff';
import Footer from 'containers/AgencyModern/Footer';
import ZoomInfoHeader1 from '../containers/Custom/Affiliate/Header';
import ZoomInfoHeader from '../containers/Custom/Affiliate/MainComHeading';
import Seo from 'components/seo';
import FaqSection from 'containers/Hosting/FaqForAff';
import Thanksgiving from '../containers/SaasModern/Thanksgiving';
import { Helmet } from 'react-helmet';
import Directory from '../containers/Directory/index';
import { graphql } from 'gatsby';
import G2Slider from '../containers/SaasModern/G2Badges';
import BookDemoButton from '../containers/SaasModern/ScheduleAdemo/ButtonDemo';

const FeaturePage = () => {
  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <Seo
          title="Join Our Affiliate Program & Earn Commission | Clodura.AI"
          description="Sign up for our Affiliate Program and earn a commission on every paid subscription to Clodura.AI. Access marketing materials and real-time tracking. Join now!"
          robots="index, follow"
          canonical={`https://www.clodura.ai/affiliate-program/`}
        />
        <Helmet>
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "How do I join the Affiliate Program?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "To join our Affiliate Program, simply sign up on our website and receive your unique referral link. Then start promoting our platform to your audience and earn commissions on every referral that results in a paid subscription."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How much commission can I earn as an affiliate?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "• As an affiliate, you can earn a commission of 15% on each monthly plan and 30% on each annual plan for the first year of each paying customer you refer. The commission will be recurring as long as the customer continues to subscribe to the plan."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "When will I get paid my commissions?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We pay out commissions on a monthly basis, as long as the minimum payout threshold of $50 has been met."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How do I track my performance as an affiliate?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We provide an easy-to-use affiliate dashboard where you can track your earnings and performance in real-time. You can also access detailed reports to help you optimize your performance and maximize your commissions"
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Is there any limit to how many customers I can refer?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "No, there is no limit to the number of customers you can refer to our platform. The more customers you refer, the more commissions you can earn."
                    }
                  }
                ]
              }
            `}
          </script>
        </Helmet>
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
              {/* <Thanksgiving /> */}
            </DrawerProvider>
          </Sticky>

          <ZoomInfoHeader1 />

          <Banner
            bannerTitle="Welcome to our Affiliate Program! "
            bannerDescription=" Our Affiliate Program is designed to reward you for promoting our products to your audience. As an affiliate, you'll earn a commission on every sale made through your unique referral link."
          />
          <ZoomInfoHeader />
          <FaqSection />
          <G2Slider />
          {/* <Directory /> */}
          <Footer />
          <BookDemoButton />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

export default FeaturePage;
